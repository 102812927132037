import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;

  &:after{
    z-index: -1;
    position: absolute;
    width: 90%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #bbb;
    content: '';
  }
`;

export const Title = styled.h2`
  z-index: 12;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: ${c.grayBGSecondary};
  color: ${c.grayText};
  padding: 0 20px;
`;

import React from 'react'
// Style
import {
  Container,
  Title
} from './TitleWithLine.styles';

const TitleWithLine = ({ children }) => {

  return(
    <Container>
      <Title>{children}</Title>
    </Container>
  )
}

export default TitleWithLine;